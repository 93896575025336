import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  ListCSKCB: [],
  ListInsuranceCompany: [],
  listProvince: [],
  idSelectedProvince: null,
  idSelectedDistrict: null,
  listDistrict: [],
  listWard: [],
  listLyDoTuChoi: [],
  listLoaiFile: [],
  loading: false,
}

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    getListCSKCB(state, action) {
      state.loading = true
    },
    getListCSKCBSuccess(state, action) {
      state.ListCSKCB = action.payload.results
      state.loading = false
      state.totalPage = action.payload.total
    },
    getListCSKCBFailed(state) {
      state.loading = false
      state.ListCSKCB = []
    },
    getListInsuranceCompany(state, action) {
      state.loading = true
    },
    getListInsuranceCompanySuccess(state, action) {
      state.ListInsuranceCompany = action.payload.result
      state.loading = false
      state.totalPage = action.payload.total
    },
    getListInsuranceCompanyFailed(state) {
      state.loading = false
      state.ListInsuranceCompany = []
    },
    getListProvince(state) {
      state.loading = true
    },
    getListProvinceSuccess(state, action) {
      state.listProvince = action.payload
    },
    getListProvinceFailed(state) {
      state.loading = false
      state.ListInsuranceCompany = []
    },
    setIdTinhThanh(state, action) {
      state.idSelectedProvince = action.payload
    },
    getListDistrict(state,action) {
      state.loading = true
    },
    getListDistrictSuccess(state, action) {
      state.listDistrict = action.payload
    },
    getListDistrictFailed(state) {
      state.loading = false
      state.listDistrict = []
    },
    setIdQuanHuyen(state, action) {
      state.idSelectedDistrict = action.payload
    },
    getListWard(state,action) {
      state.loading = true
    },
    getListWardSuccess(state, action) {
      state.listWard = action.payload
    },
    getListWardFailed(state) {
      state.loading = false
      state.listWard = []
    },
    unmountLocation(state) {
      state.listDistrict = []
      state.listProvince = []
      state.listWard = []
    },
    getListLyDoTuChoi(state, action) {
      state.loading = true
    },
    getListLyDoTuChoiSuccess(state, action) {
      state.listLyDoTuChoi = action.payload.result
      state.loading = false
      state.totalPage = action.payload.total
    },
    getListLyDoTuChoiFailed(state) {
      state.loading = false
      state.listLyDoTuChoi = []
    },
    getListLoaiFile(state, action) {
      state.loading = true
    },
    getListLoaiFileSuccess(state, action) {
      state.listLoaiFile = action.payload.result
      state.loading = false
      state.totalPage = action.payload.total
    },
    getListLoaiFileFailed(state) {
      state.loading = false
      state.listLoaiFile = []
    },
  },
})
export const categoryActions = categorySlice.actions
const categoryReducer = categorySlice.reducer

export default categoryReducer

export const selectListCSKCB = (state) => state.category.ListCSKCB
export const selectListInsuranceCompany = (state) => state.category.ListInsuranceCompany
export const selectListProvince = (state) => state.category.listProvince
export const selectListDistrict = (state) => state.category.listDistrict
export const selectIdTinhThanh = (state) => state.category.idSelectedProvince
export const selectIdQuanHuyen = (state) => state.category.idSelectedDistrict
export const selectListWard = (state) => state.category.listWard
export const selectListLyDoTuChoi = (state) => state.category.listLyDoTuChoi
export const selectListLoaiFile = (state) => state.category.listLoaiFile
