/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { App } from '../App'
import { AuthPage, Logout, useAuth } from '../pages/auth'
import { ErrorsPage } from '../pages/errors/ErrorsPage'
import { PrivateRoutes } from './PrivateRoutes'
import { MainDashboard } from 'app/modules/statistic/pages/dashboard/MainDashboard'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const { currentUser } = useAuth()

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route path='/dashboard-fullcreen/*' element={<MainDashboard />} />
              <Route index element={<Navigate to='/apps/account/overview' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />

              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
      <ToastContainer position="top-right"
        autoClose = {2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="light" />
    </BrowserRouter>
  )
}

export { AppRoutes }
