import {FULL_PATH, PREFIX} from 'app/config/router-path'
import {useAuth} from 'app/pages/auth'
import {selectMenuItem} from 'app/redux/common/commonSlice'
import moment from 'moment-timezone'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
const SidebarMenuMain = () => {
  const intl = useIntl()
  const menuItem = useSelector(selectMenuItem)
  const currenUser = useAuth()
  console.log(currenUser, 'currenUser')
  const renderMenuItem = () => {
    if (menuItem === 'danhmuc') {
      return (
        <>
          <SidebarMenuItemWithSub
            to={PREFIX.DUNG_CHUNG}
            title={intl.formatMessage({id: 'MENU.DM_COMMON'})}
            icon={'/media/common/icon_menu_danhmuc_dungchung.svg'}
            keyPer='danhmuc'
          >
            {/* Quốc gia */}
            <SidebarMenuItem
              title={intl.formatMessage({id: 'MENU.CATEGORY.NATION'})}
              to={FULL_PATH.DM_QUOC_GIA}
              keyPer='danhmuc.quocgia.xem'
            />

            <SidebarMenuItem
              title={intl.formatMessage({id: 'COMMON.PROVINCE'})}
              to={FULL_PATH.DM_TINH_THANH}
              keyPer='danhmuc.tinhthanh.xem'
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'COMMON.DISTRICT'})}
              to={FULL_PATH.DM_QUAN_HUYEN}
              keyPer='danhmuc.quanhuyen.xem'
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'COMMON.WARD'})}
              to={FULL_PATH.DM_XA_PHUONG}
              keyPer='danhmuc.phuongxa.xem'
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'COMMON.DISEASE_ID'})}
              to={FULL_PATH.DM_MA_BENH}
              keyPer='danhmuc.mabenh.xem'
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'MENU.CATEGORY.MEDICINCE_ID'})}
              to={FULL_PATH.DM_MA_THUOC}
              keyPer='danhmuc.hoatchat.xem'
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'MENU.CATEGORY.MEDICINCE_USED'})}
              to={FULL_PATH.DM_DUONG_DUNG_THUOC}
              keyPer='danhmuc.duongdungthuoc.xem'
            />
            <SidebarMenuItem
              title={intl.formatMessage({
                id: 'MENU.CATEGORY.MEDICINE_REGISTRATION_NUMBER',
              })}
              to={FULL_PATH.DM_THUOC_DUOC_CAP_SO_DANG_KI}
              keyPer='danhmuc.thuocduoccapsodangki.xem'
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'COMMON.INSURANCE_TYPE'})}
              to={FULL_PATH.DM_KIEU_BAO_HIEM}
              keyPer='danhmuc.kieubaohiem.xem'
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'COMMON.UNIT_TYPE'})}
              to={FULL_PATH.DM_KIEU_BENH_VIEN}
              keyPer='danhmuc.kieubenhvien.xem'
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'MENU.CATEGORY.MEDICAL_SUPPLIES'})}
              to={FULL_PATH.DM_VAN_TU_Y_TE}
              keyPer='danhmuc.vattuyte.xem'
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'MENU.CATEGORY.TECHNICAL_SERVICES'})}
              to={FULL_PATH.DM_DICH_VU_KY_THUAT}
              keyPer='danhmuc.dichvukythuat.xem'
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'COMMON.LIST_OF_DECISION_DIPLOMA'})}
              to={FULL_PATH.DM_VBQD}
              keyPer='danhmuc.vanbangquyetdinh.xem'
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'COMMON.LIST_OF_RULES'})}
              to={FULL_PATH.DM_RULE}
              keyPer='danhmuc.quytac.xem'
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'COMMON.LIST_OF_REASONS'})}
              to={FULL_PATH.DM_REASON}
              keyPer='danhmuc.lydo.xem'
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'COMMON.LIST_OF_TYPE_EXPORT_MATH'})}
              to={FULL_PATH.DM_TYPE_XUAT_TOAN}
              keyPer='danhmuc.loaixuattoan.xem'
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'COMMON.LIST_OF_TYPE_FILE'})}
              to={FULL_PATH.DM_TYPE_FILE}
              keyPer='danhmuc.loaifile.xem'
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'COMMON.LIST_OF_TYPE_RULES'})}
              to={FULL_PATH.DM_TYPE_RULE}
              keyPer='danhmuc.kieuquytac.xem'
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'COMMON.MANAGE_CONTRACT'})}
              to={FULL_PATH.DM_QUAN_LY_BAO_HIEM}
              keyPer='danhmuc.quanlybaohiem.xem'
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'COMMON.BENEFIT_CONTRACT'})}
              to={FULL_PATH.DM_NHOM_QUYENLOI_BAO_HIEM}
              keyPer='danhmuc.nhomquyenloibaohiem.xem'
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'COMMON.ACCIDENT_CODE'})}
              to={FULL_PATH.DM_MA_TAI_NAN}
              keyPer='danhmuc.matainan.xem'
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'COMMON.TYPE_INSURANCE'})}
              to={FULL_PATH.DM_LOAI_HINH_BAO_HIEM}
              keyPer='danhmuc.loaihinhbaohiem.xem'
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'COMMON.DEDUCTIBLE_AMOUNT'})}
              to={FULL_PATH.DM_KHOAN_KHAU_TRU}
              keyPer='danhmuc.khoankhautru.xem'
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'COMMON.INSURANCE_OPERATORS'})}
              to={FULL_PATH.DM_DON_VI_KHAI_THAC_BAO_HIEM}
              keyPer='danhmuc.donvikhaithacbaohiem.xem'
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'COMMON.BLOCKING_POINT'})}
              to={FULL_PATH.DM_DIEM_CHAN}
              keyPer='danhmuc.diemchan.xem'
            />
          </SidebarMenuItemWithSub>
          <SidebarMenuItemWithSub
            to={PREFIX.DANH_MUC_CHO_HOP_DONG}
            title={intl.formatMessage({id: 'DANHMUC.FORM.DANHMUCHOPDONG'})}
            icon={'/media/common/icon_menu_danhmuc_dungchung.svg'}
            keyPer='danhmucchohopdong'
          >
            <SidebarMenuItem
              title={intl.formatMessage({id: 'MENU.CATEGORY.XML_GROUP'})}
              to={FULL_PATH.DM_MA_NHOM_CHI_PHI}
              keyPer='danhmuc.manhomchiphi.xem'
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'MENU.CATEGORY.MEDICAL_EXAMINATION_CODE'})}
              to={FULL_PATH.DM_MA_KCB}
              keyPer='danhmuc.makcb.xem'
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'DANHMUC.FORM.DANHMUCHOPDONG.DANHMUCQUYENLOIBAOHIEM'})}
              to={FULL_PATH.DM_QUYEN_LOI_BAO_HIEM}
              keyPer='danhmucchohopdong.quyenloibaohiem.xem'
            />
            {/* <SidebarMenuItem
              title={intl.formatMessage({id: 'DANHMUC.FORM.DANHMUCHOPDONG.DANHMUCNHANVIENBAOHIEM'})}
              to={FULL_PATH.DM_NHAN_VIEN_BAO_HIEM}
              keyPer='danhmuc.diemchan.xem'
            /> */}
            <SidebarMenuItem
              title={intl.formatMessage({id: 'DANHMUC.FORM.DANHMUCHOPDONG.DANHMUCDONVITINH'})}
              to={FULL_PATH.DM_DON_VI_TINH}
              keyPer='danhmucchohopdong.donvitinh.xem'
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'DANHMUC.FORM.DANHMUCHOPDONG.DANHMUCLOAIHOPDONG'})}
              to={FULL_PATH.DM_LOAI_HOP_DONG}
              keyPer='danhmucchohopdong.loaihopdong.xem'
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'DANHMUC.FORM.DANHMUCHOPDONG.DANHMUCLOAIKHACHHANG'})}
              to={FULL_PATH.DM_LOAI_KHACH_HANG}
              keyPer='danhmucchohopdong.loaikhachhang.xem'
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'DANHMUC.FORM.DANHMUCHOPDONG.DANHMUCPHAMVIDIALY'})}
              to={FULL_PATH.DM_PHAM_VI_DIA_LY}
              keyPer='danhmucchohopdong.phamvidialy.xem'
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'DANHMUC.FORM.DANHMUCHOPDONG.DANHMUCDIEUKIENCHUNG'})}
              to={FULL_PATH.DM_DIEU_KIEN_CHUNG}
              keyPer='danhmucchohopdong.dieukienchung.xem'
            />
            <SidebarMenuItem
              title={intl.formatMessage({
                id: 'DANHMUC.FORM.DANHMUCHOPDONG.DANHMUCLOAINGUOIDUOCBAOHIEM',
              })}
              to={FULL_PATH.DM_LOAI_NGUOI_DUOC_BAO_HIEM}
              keyPer='danhmucchohopdong.loainguoiduocbaohiem.xem'
            />

            <SidebarMenuItem
              title={intl.formatMessage({id: 'DANHMUC.FORM.DANHMUCHOPDONG.DANHMUCSANPHAMBAOHIEM'})}
              to={FULL_PATH.DM_SAN_PHAM_BAO_HIEM}
              keyPer='danhmucchohopdong.sanphambaohiem.xem'
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'DANHMUC.FORM.DANHMUCHOPDONG.DANHMUCNGANHANG'})}
              to={FULL_PATH.DM_NGAN_HANG}
              keyPer='danhmucchohopdong.nganhang.xem'
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'DANHMUC.FORM.DANHMUCHOPDONG.DANHMUCMORONG'})}
              to={FULL_PATH.DM_MO_RONG}
              keyPer='danhmucchohopdong.dieukienmorong.xem'
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'DANHMUC.FORM.DANHMMUCHOPDONG.CONGTY'})}
              to={FULL_PATH.DM_CONG_TY}
              keyPer='danhmucchohopdong.congty.xem'
            />
          </SidebarMenuItemWithSub>
          <SidebarMenuItem
            to={FULL_PATH.DM_CONG_TY_BAO_HIEM}
            title={intl.formatMessage({id: 'MENU.CATEGORY.INSURANCE_COMPANY'})}
            icon={'/media/common/icon_menu_danhmuc_ctybaohiem.svg'}
            keyPer='danhmuc.congtybaohiem.xem'
          />
          <SidebarMenuItem
            title={intl.formatMessage({id: 'COMMON.LIST_OF_MEDICAL_FACILITIES'})}
            to={FULL_PATH.TDCD_DSCSKCB}
            icon={'/media/common/icon_menu_danhmuc_danhsachcosokcb.svg'}
            keyPer='danhmuc.danhsachcosokcb.xem'
          />
          <SidebarMenuItem
            title={intl.formatMessage({id: 'CONFIGURE_PAYMENT_REQUEST_APPROVAL'})}
            to={FULL_PATH.DM_CAUHINH_PHEDUYET_DENGHI_THANHTOAN}
            icon={'/media/common/icon_menu_hopdong.svg'}
            keyPer='danhmuc.cauhinhduyet.xemdanhsach'
          />
        </>
      )
    } else if (menuItem === 'thamdinhchudong') {
      return (
        <>
          <SidebarMenuItem
            title={intl.formatMessage({id: 'MENU.ACTIVE_APPRAISAL.LIST_OF_MEDICAL_RECORDS'})}
            to={FULL_PATH.HS_KCB}
            icon={'/media/common/icon_menu_thamdinhchudong_danhsachhosotttt.svg'}
            keyPer='thamdinhchudong.hosokcb.xemdanhsach'
          />
          {Array.isArray(currenUser?.currentUser?.attributes?.isTPA) &&
          currenUser.currentUser.attributes.isTPA[0] === 'false' ? (
            <SidebarMenuItem
              title={'Hồ sơ duyệt trên phân cấp'}
              to={FULL_PATH.HS_DUYET_TREN_PC}
              icon={'/media/common/icon_menu_thamdinhchudong_danhsachhosotttt.svg'}
              keyPer='thamdinhchudong.hosotttt.xemdanhsach'
            />
          ) : null}

          <SidebarMenuItem
            title={intl.formatMessage({id: 'MENU.ACTIVE_APPRAISAL.LIST_OF_DIRECT_PAYMENT_RECORDS'})}
            to={FULL_PATH.HS_HOAN_TRA}
            icon={'/media/common/icon_menu_thamdinhchudong_danhsachhosotttt.svg'}
            keyPer='thamdinhchudong.hosotttt.xemdanhsach'
          />
          <SidebarMenuItemWithSub
            to={PREFIX.THAM_DINH_CHU_DONG + PREFIX.HS_BAOLANH_VIENPHI}
            title={intl.formatMessage({
              id: 'MENU.ACTIVE_APPRAISAL.HOSPITAL_FEE_GUARANTEE_DOCUMENTS',
            })}
            icon={'/media/common/icon_menu_thamdinhchudong_danhsachhosotttt.svg'}
            keyPer='thamdinhchudong.hosotttt'
          >
            <SidebarMenuItemWithSub
              title={intl.formatMessage({
                id: 'MENU.ACTIVE_APPRAISAL.RESIDENTIAL_GUARANTEE_DOCUMENTS',
              })}
              to={PREFIX.THAM_DINH_CHU_DONG + PREFIX.HS_BAOLANH_VIENPHI + PREFIX.HS_BAOLANH_NOITRU}
              icon={'/media/common/icon_menu_thamdinhchudong_danhsachhosotttt.svg'}
              keyPer='thamdinhchudong.hosotttt'
            >
              <SidebarMenuItem
                title={intl.formatMessage({
                  id: 'MENU.ACTIVE_APPRAISAL.ESTIMATE_INPUT_DOCUMENTS',
                })}
                to={FULL_PATH.HS_DUTRU_DAUVAO}
                icon={'/media/common/icon_menu_thamdinhchudong_danhsachhosotttt.svg'}
                keyPer='thamdinhchudong.hosotttt.xemdanhsach'
              />
              <SidebarMenuItem
                title={intl.formatMessage({
                  id: 'MENU.ACTIVE_APPRAISAL.HOSPITAL_DISCHARGE_RECORDS',
                })}
                to={FULL_PATH.HS_RAVIEN}
                icon={'/media/common/icon_menu_thamdinhchudong_danhsachhosotttt.svg'}
                keyPer='thamdinhchudong.hosotttt.xemdanhsach'
              />
            </SidebarMenuItemWithSub>
            <SidebarMenuItem
              title={intl.formatMessage({
                id: 'MENU.ACTIVE_APPRAISAL.OUTPATIENT_GUARANTEE_DOCUMENTS',
              })}
              to={FULL_PATH.HS_BAOLANH_NGOAITRU}
              icon={'/media/common/icon_menu_thamdinhchudong_danhsachhosotttt.svg'}
              keyPer='thamdinhchudong.hosotttt.xemdanhsach'
            />
          </SidebarMenuItemWithSub>
        </>
      )
    } else if (menuItem === 'quantrihethong') {
      return (
        <>
          <SidebarMenuItem
            title={intl.formatMessage({id: 'COMMON.MANAGE_USER'})}
            to={FULL_PATH.QTHT_USERS}
            icon={'/media/common/icon_menu_quanlynguoidung.svg'}
            keyPer='quantrihethong.quanlynguoidung.xemdanhsach'
          />
          <SidebarMenuItem
            title={'Lịch sử người dùng'}
            to={FULL_PATH.QTHT_USERS_HISTORY}
            icon={'/media/common/icon_lichsunguoidung.svg'}
            keyPer='quantrihethong.lichsunguoidung.xemdanhsach'
          />
          <SidebarMenuItem
            title={'Quản lý phân quyền'}
            to={FULL_PATH.QTHT_USER_PERMISSION}
            icon={'/media/common/icon_menu_quanlyphanquyen.svg'}
            keyPer='quantrihethong.quanlyphanquyen.xemdanhsach'
          />
          <SidebarMenuItem
            title={'Quản lý tác vụ'}
            to={FULL_PATH.QTHT_PERMISSION_MANAGEMENT}
            icon={'/media/common/icon_tacvu.svg'}
            keyPer='quantrihethong.quanlytacvu.xemdanhsach'
          />
          <SidebarMenuItem
            title={'Quản lý nhóm quyền'}
            to={FULL_PATH.QTHT_PERMISSION_GROUP_MANAGEMENT}
            icon={'/media/common/icon_menu_quanlynhomquyen.svg'}
            keyPer='quantrihethong.quanlynhomquyen.xemdanhsach'
          />
          <SidebarMenuItem
            title={'Quản lý chức danh'}
            to={FULL_PATH.QTHT_ROLES}
            icon={'/media/common/icon_menu_quanlyphanquyen.svg'}
            keyPer='quantrihethong.quanlychucdanh.xemdanhsach'
          />
          <SidebarMenuItem
            title={'Nhật kí hệ thống'}
            to={FULL_PATH.QTHT_NHAT_KY_HE_THONG}
            icon={'/media/common/icon_menu_quanlyphanquyen.svg'}
            keyPer='quantrihethong.nhatkyhethong.xemdanhsach'
          />
          <SidebarMenuItemWithSub
            to={PREFIX.NOTIFICATION}
            title={'Notification'}
            icon={'/media/common/icon_menu_danhmuc_dungchung.svg'}
            keyPer='notification'
          >
            <SidebarMenuItem
              title={'Notification template'}
              to={FULL_PATH.QTHT_NOTIFICATION_TEMPLATE}
              icon={'/media/common/icon_menu_quanlynhomquyen.svg'}
              keyPer='quantrihethong.notification.xemdanhsach'
            />
            <SidebarMenuItem
              title={'Notification type'}
              to={FULL_PATH.QTHT_NOTIFICATION_TYPE}
              icon={'/media/common/icon_menu_quanlynhomquyen.svg'}
              keyPer='quantrihethong.notification.xemdanhsach'
            />
            <SidebarMenuItem
              title={'Client'}
              to={FULL_PATH.QTHT_CLIENT}
              icon={'/media/common/icon_menu_quanlynhomquyen.svg'}
              keyPer='quantrihethong.notification.xemdanhsach'
            />
            <SidebarMenuItem
              title={'Email SMTP'}
              to={FULL_PATH.QTHT_EMAIL_SMTP}
              icon={'/media/common/icon_menu_quanlynhomquyen.svg'}
              keyPer='quantrihethong.notification.xemdanhsach'
            />
            <SidebarMenuItem
              title={'Email API'}
              to={FULL_PATH.QTHT_EMAIL_API}
              icon={'/media/common/icon_menu_quanlynhomquyen.svg'}
              keyPer='quantrihethong.notification.xemdanhsach'
            />
            <SidebarMenuItem
              title={'SMS'}
              to={FULL_PATH.QTHT_SMS}
              icon={'/media/common/icon_menu_quanlynhomquyen.svg'}
              keyPer='quantrihethong.notification.xemdanhsach'
            />
            <SidebarMenuItem
              title={'Notification history'}
              to={FULL_PATH.QTHT_NOTIFICATION_HISTORY}
              icon={'/media/common/icon_menu_quanlynhomquyen.svg'}
              keyPer='quantrihethong.notification.xemdanhsach'
            />
            <SidebarMenuItem
              title={'Deliver log'}
              to={FULL_PATH.QTHT_DELIVERY_LOG}
              icon={'/media/common/icon_menu_quanlynhomquyen.svg'}
              keyPer='quantrihethong.notification.xemdanhsach'
            />
          </SidebarMenuItemWithSub>

          {/* <SidebarMenuItem*/}
          {/*  title={'Quản lý bộ nhớ đệm'}*/}
          {/*  to={FULL_PATH.QTHT_QUAN_LI_BO_NHO_DEM}*/}
          {/*  icon={'/media/common/icon_lichsunguoidung.svg'}*/}
          {/*/>*/}
          {/* <SidebarMenuItem
            title={'Thông số hệ thống'}
            to={FULL_PATH.QTHT_THONG_SO_HE_THONG}
            icon={'/media/common/icon_lichsunguoidung.svg'}
          /> */}
        </>
      )
    } else if (menuItem === 'tienichtrogiup') {
      return (
        <>
          <SidebarMenuItem
            title={intl.formatMessage({id: 'COMMON.TLHDSD'})}
            to={FULL_PATH.TI_TAI_LIEU_HE_THONG}
            icon={'/media/common/icon_lichsunguoidung.svg'}
            keyPer='tienichtrogiup.tailieuhdsd.xem'
          />
        </>
      )
    } else if (menuItem === 'thongkebaocao') {
      return (
        <>
          <SidebarMenuItem
            title='Dashboard'
            to={FULL_PATH.DASHBOARD_FULLSCREEN}
            icon={'/media/common/icon_menu_hopdong.svg'}
            keyPer='thongkebaocao.dashboard.xemdanhsach'
          />
          <SidebarMenuItemWithSub
            to={PREFIX.BAOCAO}
            title={intl.formatMessage({id: 'MENU.REPORT_COMPENSATION'})}
            icon={'/media/common/icon_menu_danhmuc_dungchung.svg'}
            keyPer='thongkebaocao.baocaoboithuong'
          >
            <SidebarMenuItem
              title={intl.formatMessage({id: 'MENU.REPORT_CUSTOMER'})}
              to={FULL_PATH.BAOCAO_KHACHHANG}
              keyPer='thongkebaocao.baocaoboithuong.baocaokhachhang.xemdanhsach'
              icon={'/media/common/icon_lichsunguoidung.svg'}
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'MENU.REPORT_CONTRACT'})}
              to={FULL_PATH.BAOCAO_HOPDONG}
              keyPer='thongkebaocao.baocaoboithuong.baocaohopdong.xemdanhsach'
              icon={'/media/common/icon_lichsunguoidung.svg'}
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'MENU.REPORT_PRODUCT'})}
              to={FULL_PATH.BAOCAO_SANPHAM}
              keyPer='thongkebaocao.baocaoboithuong.baocaosanpham.xemdanhsach'
              icon={'/media/common/icon_lichsunguoidung.svg'}
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'MENU.REPORT_TYPE_COMPENSATION'})}
              to={FULL_PATH.BAOCAO_LOAIHINHBOITHUONG}
              keyPer='thongkebaocao.baocaoboithuong.baocaoloaihinhboithuong.xemdanhsach'
              icon={'/media/common/icon_lichsunguoidung.svg'}
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'MENU.REPORT_INSURANCE_BENEFITS'})}
              to={FULL_PATH.BAOCAO_QUYENLOIBAOHIEM}
              keyPer='thongkebaocao.baocaoboithuong.baocaoquyenloibaohiem.xemdanhsach'
              icon={'/media/common/icon_lichsunguoidung.svg'}
            />
          </SidebarMenuItemWithSub>
          <SidebarMenuItemWithSub
            to={PREFIX.BAOCAO}
            title={intl.formatMessage({id: 'MENU.REPORT_DISEASE_MODEL'})}
            icon={'/media/common/icon_menu_danhmuc_dungchung.svg'}
            keyPer='thongkebaocao.baocaomohinhbenhtat'
          >
            <SidebarMenuItem
              title={intl.formatMessage({id: 'MENU.REPORT_BY_CHAPTER_AND_DISEASE_GROUP'})}
              to={FULL_PATH.BAOCAO_MABENH}
              keyPer='thongkebaocao.baocaomohinhbenhtat.baocaotheochuongnhombenh.xemdanhsach'
              icon={'/media/common/icon_lichsunguoidung.svg'}
            />
            <SidebarMenuItem
              title={intl.formatMessage({id: 'MENU.REPORT_BY_MEDICAL_FACILITY'})}
              to={FULL_PATH.BAOCAO_MHBT_CSKCB}
              keyPer='thongkebaocao.baocaomohinhbenhtat.baocaotheocskcb.xemdanhsach'
              icon={'/media/common/icon_lichsunguoidung.svg'}
            />
          </SidebarMenuItemWithSub>
          <SidebarMenuItemWithSub
            to={PREFIX.BAOCAO}
            title={intl.formatMessage({id: 'MENU.REPORT_RECORD'})}
            icon={'/media/common/icon_menu_danhmuc_dungchung.svg'}
            keyPer='thongkebaocao.baocaohoso'
          >
            <SidebarMenuItem
              title={intl.formatMessage({id: 'MENU.REPORT_LIST_RECORD'})}
              to={FULL_PATH.BAOCAO_DANHSACHHOSO}
              keyPer='thongkebaocao.baocaohoso.danhsachhoso.xemdanhsach'
              icon={'/media/common/icon_lichsunguoidung.svg'}
            />
          </SidebarMenuItemWithSub>
        </>
      )
    } else if (menuItem === 'hopdong') {
      return (
        <>
          {/* <SidebarMenuItem
            title={intl.formatMessage({id: 'COMMON.TDSHDKH'})}
            to={FULL_PATH.TAO_HOP_DONG}
            icon={'/media/common/icon_menu_hopdong.svg'}
          /> */}
          <SidebarMenuItem
            title={intl.formatMessage({id: 'COMMON.DSHDKH'})}
            to={FULL_PATH.HOP_DONG}
            icon={'/media/common/icon_menu_hopdong.svg'}
            keyPer='hopdong.xemdanhsach'
          />
          <SidebarMenuItem
            title={intl.formatMessage({id: 'COMMON.DSTKCT'})}
            to={FULL_PATH.TAI_KHOAN_CHI_TRA}
            icon={'/media/common/icon_menu_hopdong.svg'}
            keyPer='hopdong.taikhoanchitra.xemdanhsach'
          />
        </>
      )
    } else if (menuItem === 'thanhtoan') {
      return (
        <>
          <SidebarMenuItem
            title={intl.formatMessage({id: 'THANHTOAN.FORM.DANHSACHHOSOTHANHTOAN'})}
            to={FULL_PATH.THANHTOAN_DANHSACHHOSOTHANHTOAN}
            icon={'/media/common/icon_menu_hopdong.svg'}
            keyPer='thanhtoan.danhsachhosothanhtoan.xem'
          />
          <SidebarMenuItem
            title={intl.formatMessage({id: 'THANHTOAN.FORM.DANHSACHDENGHIDUYETTHANHTOAN'})}
            to={FULL_PATH.THANHTOAN_DANHSACHDENGHIDUYETTHANHTOAN}
            icon={'/media/common/icon_menu_hopdong.svg'}
            keyPer='thanhtoan.danhsachdenghiduyetthanhtoan.xem'
          />
          {/* Thanh toán ver 2 */}
          {/* <SidebarMenuItem
            title={intl.formatMessage({id: 'LIST_OF_PAYMENT_PROFILE_VERSION_2'})}
            to={FULL_PATH.THANHTOAN_DANHSACHHOSOTHANHTOAN_VER2}
            icon={'/media/common/icon_menu_hopdong.svg'}
            keyPer='thanhtoan.danhsachhosothanhtoan.xem'
          />
          <SidebarMenuItem
            title={intl.formatMessage({id: 'LIST_OF_PAYMENT_REQUEST_VERSION_2'})}
            to={FULL_PATH.THANHTOAN_DANHSACHPHIEUDENGHITHANHTOAN_VER2}
            icon={'/media/common/icon_menu_hopdong.svg'}
            keyPer='thanhtoan.danhsachdenghiduyetthanhtoan.xem'
          /> */}
        </>
      )
    } else if (menuItem === 'thanhquyettoan') {
      return (
        <>
          {/* Thanh toán ver 2 */}
          <SidebarMenuItem
            title={intl.formatMessage({id: 'LIST_OF_PAYMENT_PROFILE_VERSION_2'})}
            to={FULL_PATH.THANHTOAN_DANHSACHHOSOTHANHTOAN_VER2}
            icon={'/media/common/icon_menu_hopdong.svg'}
            keyPer='thanhquyettoan.hosothanhtoan.xemdanhsach'
          />
          <SidebarMenuItem
            title={intl.formatMessage({id: 'LIST_OF_PAYMENT_REQUEST_VERSION_2'})}
            to={FULL_PATH.THANHTOAN_DANHSACHPHIEUDENGHITHANHTOAN_VER2}
            icon={'/media/common/icon_menu_hopdong.svg'}
            keyPer='thanhquyettoan.hosothanhtoan.xemdanhsach'
          />
          <SidebarMenuItem
            title={'Quản lý truyền nhận'}
            to={FULL_PATH.THANHTOAN_TRUYENNHANDULIEU}
            icon={'/media/common/icon_menu_hopdong.svg'}
            keyPer='thanhquyettoan.quanlytruyennhan.xemdanhsach'
          />
        </>
      )
    } else if (menuItem === 'thamdinhtudong') {
      return (
        <>
          <SidebarMenuItem
            title={'Chạy quy tắc thẩm định'}
            to={FULL_PATH.TDTD_QUYTAC_TUDONG}
            icon={'/media/common/icon_menu_hopdong.svg'}
            keyPer='thamdinhtudong.quytactudong.xemdanhsach'
          />
          <SidebarMenuItem
            title={'DS quy tắc thẩm định tự động'}
            to={FULL_PATH.TDTD_DANHSACH_QUYTAC_TUDONG}
            icon={'/media/common/icon_menu_hopdong.svg'}
            keyPer='thamdinhtudong.quytactudong.xemdanhsach'
          />
        </>
      )
    } else if (menuItem === 'quyettoan') {
      return (
        <>
          <SidebarMenuItem
            title={'Tổng hợp báo cáo'}
            to={FULL_PATH.BAO_CAO}
            icon={'/media/common/icon_menu_hopdong.svg'}
            keyPer='quyettoan.tonghopbaocao.xem'
          />
        </>
      )
    }
  }
  return (
    <div style={{display: 'flex', flexDirection: 'column', height: '90vh'}}>
      <div style={{flexGrow: 1}}>{renderMenuItem()}</div>
      <div
        style={{paddingLeft: 14, fontSize: 14, fontWeight: 'bolder', color: '#5E6278'}}
        className='menu-title'
      >
        Múi giờ hiện tại:
        <div>
          <span>{`(UTC${moment.tz(moment.tz.guess()).format('Z')})`}</span>{' '}
          <span>{moment.tz.guess()}</span>
        </div>
      </div>
    </div>
  )
}

export {SidebarMenuMain}
