import {embedDashboard} from '@superset-ui/embedded-sdk'
import axios from 'axios'
import React from 'react'
import './style.scss'
import {api} from 'app/config/api'
import ToastMessage from '../ToastMessage'

interface SupersetDashboardProps {
  fromDate: Date;
  toDate: Date;
  idDonViBaoHiem: string;
  idDonVi: string;
  setMessageErrorTime?: any;
  messagErrorTime?: string;
  setMessagErrorSelectCongTyBH?: any;
  setMessagErrorSelectMaHopDong?: any;
  maHopDong?: any;
  type?: any;
  idCongTyKhachHang: any;
  idSuperset: any;
}

class SupersetDashboard extends React.Component<SupersetDashboardProps> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    const { fromDate, toDate, idDonViBaoHiem, idDonVi, maHopDong } = this.props;
    if (this.props.type !== '2') {
      this.embed(fromDate, toDate, idDonViBaoHiem, idDonVi, maHopDong);
    }
  }

  componentDidUpdate(prevProps: SupersetDashboardProps) {
    const {
      fromDate: newFromDate,
      toDate: newToDate,
      idDonViBaoHiem,
      idDonVi,
      maHopDong,
      idCongTyKhachHang,
    } = this.props;
    const {
      fromDate: prevFromDate,
      toDate: prevToDate,
      idDonVi: prevIdDonVi,
      maHopDong: prevMaHopDong,
      idCongTyKhachHang: prevIdCongTyKhachHang,
    } = prevProps;

    if (
      newFromDate !== prevFromDate ||
      newToDate !== prevToDate ||
      idDonVi !== prevIdDonVi ||
      maHopDong !== prevMaHopDong ||
      idCongTyKhachHang !== prevIdCongTyKhachHang
    ) {
      if (this.props.type === '2' && this.props.idSuperset) {
        this.embedDashboard();
      } else {
        this.embed(newFromDate, newToDate, idDonViBaoHiem, idDonVi, maHopDong);
      }
    }
  }

  async fetchAdminToken() {
    try {
      const response = await axios.post(
        `${api.API_BAO_CAO}/superset/${this.props.idSuperset}/token`
      );

      let guestToken = '';
      if (response.data) {
        guestToken = response.data.token;
      }
      return guestToken;
    } catch (error) {
      ToastMessage('error', 'Không tìm thấy danh sách tệp!');
      throw error;
    }
  }

  async embed(
    fromDate: any,
    toDate: any,
    idCongTyBaoHiem: string,
    idDonVi: string,
    maHopDong: any
  ) {
    console.log(maHopDong[0], 'MAHD');
    const fromTimestamp = fromDate
      ? typeof fromDate === 'number'
        ? fromDate
        : fromDate.getTime()
      : 'None';
    const toTimestamp = toDate
      ? typeof toDate === 'number'
        ? toDate
        : toDate.getTime()
      : 'None';

    if (!(toDate && fromDate)) {
      this.props.setMessageErrorTime('Thời gian không được để trống');
      if (this.props.idDonVi) {
        this.props.setMessagErrorSelectCongTyBH('');
        if (this.props.maHopDong?.length === 0) {
          this.props.setMessagErrorSelectMaHopDong('Vui lòng chọn số hợp đồng bảo hiểm');
        } else this.props.setMessagErrorSelectMaHopDong('');
      } else {
        this.props.setMessagErrorSelectCongTyBH('Vui lòng chọn công ty bảo hiểm');
      }
    } else {
      if (fromTimestamp > toTimestamp) {
        this.props.setMessageErrorTime('Từ ngày không được lớn hơn đến ngày');
      } else {
        if (this.props.type === '2') {
          this.embedDashboard();
        } else {
          if (!this.props.idDonVi) {
            this.props.setMessagErrorSelectCongTyBH('Vui lòng chọn công ty bảo hiểm');
          } else {
            if (this.props.maHopDong?.length === 0) {
              this.props.setMessagErrorSelectMaHopDong('Vui lòng chọn số hợp đồng bảo hiểm');
            } else this.props.setMessagErrorSelectMaHopDong('');
            this.props.setMessagErrorSelectCongTyBH('');
            this.props.setMessageErrorTime('');

            const urlParams = {
              fromDate: fromTimestamp,
              toDate: toTimestamp,
              idCongTyBaoHiem: idCongTyBaoHiem,
              donViId: idDonVi,
              ...(maHopDong?.length > 0 &&
                maHopDong[0] !== null && { maHopDong: `${maHopDong?.join(',')}` }),
              ...(this.props.type === '2' && {
                idCongTyKhachHang: `${this.props.idCongTyKhachHang?.join(',')}`,
              }),
            };

            const embed = await embedDashboard({
              id: this.props.idSuperset,
              supersetDomain: 'https://thamdinh-dashboard.azinsu.com/',
              mountPoint: document.getElementById('my-superset-container') as HTMLElement,
              fetchGuestToken: () => this.fetchAdminToken(),
              dashboardUiConfig: {
                hideTitle: true,
                hideChartControls: true,
                hideTab: true,
                filters: {
                  expanded: false,
                  visible: false,
                },
                urlParams,
              },
            });
          }
        }
      }
    }
  }

  async embedDashboard() {
    const fromTimestamp = this.props.fromDate
      ? typeof this.props.fromDate === 'number'
        ? this.props.fromDate
        : this.props.fromDate.getTime()
      : 'None';
    const toTimestamp = this.props.toDate
      ? typeof this.props.toDate === 'number'
        ? this.props.toDate
        : this.props.toDate.getTime()
      : 'None';

    const urlParams = {
      fromDate: fromTimestamp,
      toDate: toTimestamp,
      idCongTyKhachHang: `${this.props.idCongTyKhachHang?.join(',')}`,
    };

    await embedDashboard({
      id: this.props.idSuperset,
      supersetDomain: 'https://thamdinh-dashboard.azinsu.com/',
      mountPoint: document.getElementById('my-superset-container') as HTMLElement,
      fetchGuestToken: () => this.fetchAdminToken(),
      dashboardUiConfig: {
        hideTitle: true,
        hideChartControls: true,
        hideTab: true,
        filters: {
          expanded: false,
          visible: false,
        },
        urlParams,
      },
    });
  }

  render() {
    return <div id='my-superset-container' className='my-superset-container'></div>;
  }
}

export default SupersetDashboard;