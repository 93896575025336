import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  reload: false,
  initRolesGetList: false,
  objectSearch: {
    page: 0,
    items_per_page: 10,
    tongThanhToan: [-1, -1],
    tienDuyet: [-1, -1],
    kyQt: null,
    donviBHSK: null,
    donviTD: null,
    canhBaoChiPhi: null,
    maCSKCB: null,
    trangThaiHs: null,
    gioiTinh: null,
    typeTGRV: null,
    ngayVao: null,
    ngayRa: null,
    mot_ngay: null,
  },
  objectSearchDefault: {
    page: 0,
    items_per_page: 10,
    tongThanhToan: [-1, -1],
    tienDuyet: [-1, -1],
    kyQt: null,
    donviBHSK: null,
    donviTD: null,
    canhBaoChiPhi: null,
    maCSKCB: null,
    trangThaiHs: null,
    gioiTinh: null,
    typeTGRV: null,
    ngayVao: null,
    ngayRa: null,
    mot_ngay: null,
    trangThaiHoSo: null,
  },
  objectSearchFilter: {
    page: 0,
    items_per_page: 10,
    tongThanhToan: [-1, -1],
    tienDuyet: [-1, -1],
    kyQt: null,
    donviBHSK: null,
    donviTD: null,
    canhBaoChiPhi: null,
    maCSKCB: null,
    trangThaiHs: null,
    gioiTinh: null,
    typeTGRV: null,
    ngayVao: null,
    ngayRa: null,
    mot_ngay: null,
    idLanhDao: null,
  },
  objectSearchDirectPayemnt: {
    page: 0,
    items_per_page: 10,
    thoiGianRaVao: [0, 0],
    tongThanhToan: [0, -1],
    tongTienDuyet: [0, -1],
    tongVienPhi: [0, -1],
    dsDonViBHSK: null,
    idsDonViBHSK: null,
    dsDonViThamDinh: null,
    canhBaoChiPhi: null,
    maCSKCB: null,
    trangThaiHoSo: null,
    gioiTinh: null,
    typeTGRV: null,
    ngay_vao: null,
    ngay_ra: null,
    mot_ngay: null,
    tenBenh: null,
    phanLoaiKCB:null,
    listPhanLoaiKCB:null,
  },
  objectSearchDirectPayemntDefault: {
    page: 0,
    items_per_page: 10,
    thoiGianRaVao: [0, 0],
    tongThanhToan: [0, -1],
    tongTienDuyet: [0, -1],
    tongVienPhi: [0, -1],
    dsDonViBHSK: null,
    idsDonViBHSK: null,
    dsDonViThamDinh: null,
    canhBaoChiPhi: null,
    maCSKCB: null,
    trangThaiHoSo: null,
    gioiTinh: null,
    typeTGRV: null,
    ngay_vao: null,
    ngay_ra: null,
    mot_ngay: null,
    tenBenh: null,
    phanLoaiKCB:null,
    listPhanLoaiKCB:null,
  },
  objectSearchDirectPayemntFilter: {
    page: 0,
    items_per_page: 10,
    thoiGianRaVao: [0, 0],
    tongThanhToan: [0, -1],
    tongTienDuyet: [0, -1],
    tongVienPhi: [0, -1],
    dsDonViBHSK: null,
    dsDonViThamDinh: null,
    idsDonViBHSK: null,
    canhBaoChiPhi: null,
    maCSKCB: null,
    maHopDong: null,
    trangThaiHoSo: null,
    gioiTinh: null,
    typeTGRV: null,
    ngay_vao: null,
    ngay_ra: null,
    mot_ngay: null,
    tenBenh: null,
    nguoiDuyetTuChoi: null,
    selectedLanhDao: null,
    phanLoaiKCB:null,
    listPhanLoaiKCB:null,
  },
  listMedicalRecord: [],
  loading: true,
  loadingPayment: true,
  totalPage: null,
  totalPageTTTT: null,
  objectPrevFowardId: {
    page: 0,
    items_per_page: 10,
    listId: [],
    currentProfileIndex: 0,
    idSelected: null,
  },
  objectPrevFowardIdTTTT: {
    page: 0,
    items_per_page: 10,
    listId: [],
    currentProfileIndex: 0,
    idSelected: null,
  },
  objectPrevFowardIdDTBL: {
    page: 0,
    items_per_page: 10,
    listId: [],
    currentProfileIndex: 0,
    idSelected: null,
  },
  loadingMedicalRecordPrevNextPage: false,
  loadingMedicalRecordPrevNextPageTTTT: false,
  listMedicalExpense: [],
  idHoSo: null,
}

export const searchFilterSlice = createSlice({
  name: 'searchFilter',
  initialState,
  reducers: {
    setListMedicalRecord(state, action) {
      state.listMedicalRecord = action.payload
    },
    getListMedicalRecord(state, action) {
      state.loading = true
    },
    getListMedicalRecordSuccess(state, action) {
      state.listMedicalRecord = action.payload.results
      state.loading = false
      state.totalPage = action.payload.total
    },
    getListMedicalRecordFailed(state) {
      state.loading = false
      state.listMedicalRecord = []
    },
    unmountMedicalRecord(state) {
      state.listMedicalRecord = []
      state.loading = true
    },
    setObjectSearch(state, action) {
      state.objectSearch = {...state.objectSearch, ...action.payload}
      state.objectSearchFilter = {...state.objectSearchFilter, ...action.payload}
    },
    setObjectSearchNoMerge(state, action) {
      state.objectSearch = {...action.payload, reload: !state.reload}
    },
    setObjectSearchFilter(state, action) {
      state.objectSearchFilter = {...state.objectSearchFilter, ...action.payload}
    },
    setObjectSearchFilterNoMerge(state, action) {
      state.objectSearchFilter = {...action.payload}
    },
    setObjectSearchFilterDefault(state) {
      state.objectSearchFilter = initialState.objectSearchFilter
    },
    setListDirectPaymentRecord(state, action) {
      state.listDirectPaymentRecord = action.payload
    },
    getListDirectPaymentRecord(state, action) {
      state.loadingPayment = true
    },
    getListDirectPaymentRecordSuccess(state, action) {
      state.listDirectPaymentRecord = action.payload.results
      state.loadingPayment = false
      state.totalPageTTTT = action.payload.total
    },
    getListDirectPaymentRecordFailed(state) {
      state.loadingPayment = false
      state.listDirectPaymentRecord = []
      state.totalPageTTTT = 0
    },
    unmountDirectPaymentRecord(state) {
      state.listDirectPaymentRecord = []
      state.loadingPayment = true
    },
    setObjectSearchPayment(state, action) {
      state.objectSearchDirectPayemnt = {...state.objectSearchDirectPayemnt, ...action.payload}
      state.objectSearchDirectPayemntFilter = {
        ...state.objectSearchDirectPayemntFilter,
        ...action.payload,
      }
    },
    setObjectSearchDirectPaymentNoMerge(state, action) {
      console.log(action.payload)
      state.objectSearchDirectPayemnt = {...action.payload, reload: !state.reload}
      state.objectSearchDirectPayemntFilter = {...action.payload, reload: !state.reload}
    },
    setObjectSearchPaymentDefault(state) {
      state.objectSearchDirectPayemnt = initialState.objectSearchDirectPayemnt
      state.objectSearchDirectPayemntFilter = initialState.objectSearchDirectPayemntFilter
    },
    setObjectSearchPaymentFilter(state, action) {
      state.objectSearchDirectPayemntFilter = {
        ...state.objectSearchDirectPayemntFilter,
        ...action.payload,
      }
    },
    setObjectSearchFilterPaymentDefault(state) {
      state.objectSearchDirectPayemntFilter = initialState.objectSearchDirectPayemntFilter
    },
    setInitRolesGetList(state, action) {
      state.initRolesGetList = action.payload
    },
    setObjectPrevFowardId(state, action) {
      state.objectPrevFowardId = {...state.objectPrevFowardId, ...action.payload}
    },
    setObjectPrevFowardIdTTTT(state, action) {
      state.objectPrevFowardIdTTTT = {...state.objectPrevFowardIdTTTT, ...action.payload}
    },
    setObjectPrevFowardIdDTBL(state, action) {
      state.objectPrevFowardIdDBL = {...state.objectPrevFowardIdDTBL, ...action.payload}
    },
    getListMedicalRecordPrevNextPage(state, action) {
      state.loadingMedicalRecordPrevNextPage = true
    },
    getListMedicalRecordPrevNextPageSuccess(state, action) {
      state.loadingMedicalRecordPrevNextPage = false
    },
    getListMedicalRecordPrevNextPageFailed(state, action) {
      state.loadingMedicalRecordPrevNextPage = false
    },
    getListMedicalRecordPrevNextPageTTTT(state, action) {
      state.loadingMedicalRecordPrevNextPageTTTT = true
    },
    getListMedicalRecordPrevNextPageTTTTSuccess(state, action) {
      state.loadingMedicalRecordPrevNextPageTTTT = false
    },
    getListMedicalRecordPrevNextPageTTTTFailed(state, action) {
      state.loadingMedicalRecordPrevNextPageTTTT = false
    },
    postMedicalExpense(state, action) {
      state.loading = true
    },
    postMedicalExpenseSuccess(state, action) {
      state.listMedicalExpense = action.payload.results
      state.loading = false
      state.totalPage = action.payload.total
    },
    postMedicalExpenseFailed(state) {
      state.loading = false
      state.listMedicalExpense = []
    },
    setIdHoSo(state, action) {
      state.idHoSo = action.payload
    },
  },
})
export const searchFilterActions = searchFilterSlice.actions
const searchFilterReducer = searchFilterSlice.reducer

export default searchFilterReducer

export const selectListMedicalRecord = (state) => state.searchFilter.listMedicalRecord
export const selectObjectSearch = (state) => state.searchFilter.objectSearch
export const selectObjectSearchDefault = (state) => state.searchFilter.objectSearchDefault
export const selectObjectSearchFilter = (state) => state.searchFilter.objectSearchFilter
export const selectTotalPage = (state) => state.searchFilter.totalPage
export const selectTotalPageTTTT = (state) => state.searchFilter.totalPageTTTT
export const selectSearchFilter = (state) => state.searchFilter
export const selectLoading = (state) => state.searchFilter.loading
export const selectObjectPrevFowardId = (state) => state.searchFilter.objectPrevFowardId
export const selectObjectPrevFowardIdTTTT = (state) => state.searchFilter.objectPrevFowardIdTTTT
export const selectObjectPrevFowardIdDTBL = (state) => state.searchFilter.objectPrevFowardIdDTBL
export const selectLoadingPayment = (state) => state.searchFilter.loadingPayment
export const selectObjectSearchDirectPayemntFilter = (state) =>
  state.searchFilter.objectSearchDirectPayemntFilter
export const selectListDirectPaymentRecord = (state) => state.searchFilter.listDirectPaymentRecord
export const selectObjectSearchDirectPayemnt = (state) =>
  state.searchFilter.objectSearchDirectPayemnt
export const selectObjectSearchDirectPayemntDefault = (state) =>
  state.searchFilter.objectSearchDirectPayemntDefault
export const selectInitRolesGetList = (state) => state.searchFilter.initRolesGetList
export const selectLoadingMedicalRecordPrevNextPage = (state) =>
  state.searchFilter.loadingMedicalRecordPrevNextPage
export const selectLoadingMedicalRecordPrevNextPageTTTT = (state) =>
  state.searchFilter.loadingMedicalRecordPrevNextPageTTTT
export const selectIdHoSo = (state) =>
  state.searchFilter.idHoSo
